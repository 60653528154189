import ProjectApis from 'src/pages/Project/apis';
import MonitorApis from 'src/pages/Monitor/apis';
import LoginApis from 'src/pages/Login/api';
import AnalysisApis from 'src/pages/Analysis/apis';
const apis = {
  project: new ProjectApis(),
  user: new LoginApis(),
  monitor: new MonitorApis(),
  analysis: new AnalysisApis()
};

export default apis;