import { createGetApi, createPostApi } from 'friday-async';

class Apis {
  // 活动列表
  public detail = createGetApi<{date: string}>({
    url: '/monitor/get',
  });

  public getLeads = createGetApi<{
    startTime: string;
    endTime: string;
    status: number;
  }>({
    url: '/youku/getLeads',
    baseURL: '/api/activity'
  });
}

export default Apis;