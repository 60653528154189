import React from 'react';
import { RouterProvider } from 'react-router-dom';
import ApiProvider from 'src/apis/apiProvider';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/locale/zh_CN';
import 'dayjs/locale/zh-cn';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween'
import { router } from './navigation'
dayjs.locale('zh-cn');
dayjs.extend(isBetween)


function App() {
  return (
    <ConfigProvider locale={zhCN}>
      <ApiProvider>
        <RouterProvider router={router} />
      </ApiProvider>
    </ConfigProvider>
  );
}

export default App;


