// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-picker-calendar-date-content .events {
  margin: 0;
  padding: 0;
  list-style: none;
}
.link {
  color: inherit;
  text-decoration: none;
}
.link:hover {
  color: inherit;
  text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Project/Detail/index.less"],"names":[],"mappings":"AAEA;EAEQ,SAAA;EACA,UAAA;EACA,gBAAA;AAFR;AAMA;EACI,cAAA;EACA,qBAAA;AAJJ;AAMI;EACI,cAAA;EACA,qBAAA;AAJR","sourcesContent":["\n\n.ant-picker-calendar-date-content {\n    .events {\n        margin: 0;\n        padding: 0;\n        list-style: none;\n    }\n}\n\n.link {\n    color: inherit;\n    text-decoration: none;\n\n    &:hover {\n        color: inherit;\n        text-decoration: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
