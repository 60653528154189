import ExcelJS from 'exceljs';
import type { Column  } from 'exceljs';
import dayjs from 'dayjs';

export type ExcelColumn = {
  title: string;
  dataIndex: string;
  width?: number;
  // 下面三个都是设置表头的
  align?: 'center' | 'left';
  color?: string;
  bgColor?: string;
  // 如果需要设置其他单元格，可以扩展
  columnBgColor?: string;
  valueType?: 'string' | 'stringArray',
  valueEnum?: Record<string, {
    text: string;
    [x: string]: any;
  }>;
};

type CreateExcelOptions = {
  fileName?: string;
  sheetName?: string;
  columns: ExcelColumn[];
  dataSource: any[];
  initialExcelStyle?: Partial<Column>;
};

type ColumnsMap =  Record<string, ExcelColumn> ;

const defaultExcelStyle: Partial<Column> = {
  width: 250,
  alignment: { vertical: 'middle', horizontal: 'left' },
  font: {
    size: 10,
    bold: true,
    color: { argb: '000000' },
  },
  border: {
    top: { style: 'thin', color: { argb: 'd4dbe9' } },
    left: { style: 'thin', color: { argb: 'd4dbe9' } },
    bottom: { style: 'thin', color: { argb: 'd4dbe9' } },
    right: { style: 'thin', color: { argb: 'd4dbe9' } },
  },
};

export const useExcel = () => {
  const createExcel = async (options: CreateExcelOptions) => {
    const { sheetName = 'Sheet 1', fileName, columns, dataSource, initialExcelStyle = {} } = options;
    const nextStyle: any = { ...defaultExcelStyle, ...initialExcelStyle };

    if (!columns.length) throw 'columns is empty';
    const workbook = new ExcelJS.Workbook();

    workbook.creator = 'wxq';
    workbook.created = new Date();

    const worksheet = workbook.addWorksheet(sheetName);
    const columnsMap: ColumnsMap = {};
    // 表头
    const columnsData = columns.map((column) => {
      columnsMap[column.dataIndex] = column;
      return {
        header: column.title,
        key: column.dataIndex,
        width: (column.width || nextStyle.width) / 10,
      };
    });
    worksheet.columns = columnsData;

    // 设置表头样式
    const headerRow = worksheet.getRow(1);
    headerRow.height = 30;
    headerRow.eachCell((cell) => {
      const key = (cell as any)._column._key;
      const { bgColor, color, align } = columnsMap[key];
      if (bgColor) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: bgColor },
        };
      };
      cell.font = {
        size: 12,
        bold: true,
        color: { argb: color ? color : nextStyle.font.color.argb },
      };
      cell.alignment = { vertical: 'middle', horizontal: align ? align : 'center' };
    });

    // 数据导入
    worksheet.addRows(formatDataSource(columnsMap, dataSource));

    const dataLength = dataSource.length + 1;
    // 整体样式 - 有数据的
    worksheet.eachRow((row, rowNumber) => {
      if (dataLength >= rowNumber) {
        ;
        row.eachCell((cell) => {
          const dataIndex = (cell as any)?._column._key;
          const { columnBgColor } = columnsMap[dataIndex];
          if (columnBgColor) {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: columnBgColor },
            };
          };
          cell.border = nextStyle.border;
          cell.font = nextStyle.font;
          cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' };
        });
      }
    });
    const nextFileName = fileName
      ? `${fileName}.xlsx`
      : `${dayjs().format('YYYY-MM-DD')}.xlsx`;

    downloadExcel(workbook, nextFileName);
  };

  return {
    createExcel,
  };
};


const formatDataSource = (columnsMap: ColumnsMap, dataSource: any[]) => dataSource?.map((rowData) => {
  const nextRowData = Object.keys(rowData).reduce((pre, key) => {
    // filter key
    if (!columnsMap[key]) return pre;
    const { valueEnum, valueType } = columnsMap[key];
    const dataVal = rowData[key];
    // 枚举数据映射
    if (valueEnum) {
      const val = valueType === 'stringArray'
        ? dataVal?.map(i => valueEnum[i].text).join('\n')
        : valueEnum?.[dataVal]?.text;
      return { ...pre, [key]: val || '/' };
    };
    // 不映射
    const val = valueType === 'stringArray'
      ? dataVal?.map(i => i).join('\n')
      : dataVal;
    return { ...pre, [key]: val === undefined ? '/' : val };
  }, {});
  return nextRowData;
});

const downloadFromBlob = (blobUrl: string, title?: string) => {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.download = title || '无名称';
  a.target = '_blank';
  a.href = blobUrl;
  a.click();
  window.URL.revokeObjectURL(a.href);
  document.body.removeChild(a);
};

const downloadExcel = async (workbook: ExcelJS.Workbook, fileName: string) => {
  if (typeof window === 'undefined') {
    return await workbook.xlsx.writeFile(fileName);
  }
  const blob = new Blob([await workbook.xlsx.writeBuffer()]);
  const url = window.URL.createObjectURL(blob);
  downloadFromBlob(url, fileName);
};