export const typeName = {
    fixed: '固定周期',
    flex: '灵活周期'
  }
  
export const statusMap = {
    effective: {
      label: '推送中',
      color: '#87d068'
    },
    unEffective: {
      label: '暂停推送',
      color: '#f50'
    },
  }


export const projectStatusMap = {
    effective: {
      label: '生效中',
      color: '#87d068'
    },
    unEffective: {
      label: '已过期',
      color: '#f50'
    },
  }
  
  