import { createGetApi, createPostApi } from 'friday-async';

class Apis {
  // 活动列表

  public detail = createGetApi<{_id: string}>({
    url: '/project/detail',
  });

  public calendar = createGetApi<{
    projectId: string;
    startTime: string;
    endTime: string;
    mode: string
  }>({
    url: '/project/calendar',
  });

  public list = createGetApi<void>({
    url: '/project/list',
  });

  public craete = createPostApi({
    url: '/project/create',
  });

  public batchDelete = createPostApi<{ids: string[]}>({
    url: '/project/batchDelete',
  });

  public leadList = createGetApi<{projectId: string, createTime: string}>({
    url: '/lead/list',
  });

  public leadReturnList = createGetApi<{
    projectId: string, 
    startTime: string,
    endTime: string,
    demandStatus: string | undefined
  }>({
    url: '/return/list',
  });

  public batchDeleteLead = createPostApi<{ids: string[]}>({
    url: '/lead/batchDelete',
  });

  public demandlist = createPostApi<void>({
    url: '/project/demandlist',
  });
  public demandDetail = createPostApi<{crid: string}>({
    url: '/project/demandDetail',
  });

  public getCalendar = createGetApi<any>({
    url: '/project/calendar',
  });

  public downloadTemplate = createGetApi<void>({
    url: '/lead/download/template',
    responseType: 'blob'
  });

  public downloadLeads = createGetApi<{projectId: string, createTime: string}>({
    url: '/lead/download',
    responseType: 'blob'
  });

  public downloadReturnLeads = createGetApi<{
    projectId: string, 
    startTime: string,
    endTime: string,
    demandStatus: string | undefined
  }>({
    url: '/return/download',
    responseType: 'blob'
  });
}

export default Apis;