import { useExcel } from '../../hooks'
import { SendStatusName, ActivityTypeMap } from './constants';
export const useExcelService = () => {
    const {  createExcel }  = useExcel()
    const columns = [
        {
            title: 'id',
            dataIndex: '_id',
            key: '_id',
            width: 200,
        },
        {
            title: '手机号',
            dataIndex: 'tel',
            key: 'tel',
            width: 160,
        },
        {
            title: '活动类型',
            dataIndex: 'jobName',
            key: 'jobName',
            width: 200,
            valueEnum: ActivityTypeMap
        },
        {
            title: '车系',
            dataIndex: 'seriesId',
            key: 'seriesId',
            width: 100,
        },
        {
            title: '权益成功数',
            dataIndex: 'receiveSuccessLenth',
            key: 'receiveSuccessLenth',
            width: 110,
        },
        {
            title: '权益成功ID',
            dataIndex: 'receiveSuccessCouponId',
            key: 'receiveSuccessCouponId',
            width: 120,
        },
        {
            title: '权益失败数',
            dataIndex: 'receiveFailLenth',
            key: 'receiveFailLenth',
            width:120,
        },
        {
            title: '权益失败明细',
            dataIndex: 'receiveFailComment',
            key: 'receiveFailComment',
            width: 160,
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            width: 180,
        },
        {
            title: '一丰回传',
            dataIndex: 'sendStatus',
            key: 'sendStatus',
            valueEnum: SendStatusName
        },
        {
            title: '发送短信',
            dataIndex: 'isSendShotMsg',
            key: 'isSendShotMsg',
            valueEnum: SendStatusName
        },
        {
            title: '试驾协议',
            dataIndex: 'assest',
            key: 'assest',
        },
        {
            title: 'ocpc回传',
            dataIndex: 'conversionStatus',
            key: 'conversionStatus',
            valueEnum: SendStatusName
        },
    ] as any[]

    const onDownloadLeads = (fileName, dataSource) => {
        createExcel({
            fileName,
            columns,
            dataSource,
        })
    }

    return {
        onDownloadLeads,
    }
}