import React, { useState } from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom'
import { Layout, Button, Form, Input, message } from 'antd';
import { dispatchAsync } from 'friday-async'
import { useApiSelector } from "src/hooks";
import jsCookie from 'js-cookie'

import './index.less'

const Login = () => {

    const apis = useApiSelector()

    const navigate = useNavigate()
    
    const onFinish = async (val) => {
        const { responseJson } = await dispatchAsync(apis.user.login(val))

        const { code, data }  = responseJson
        if (code === -1) {
            return message.error((responseJson as any).msg)
        }
        
        jsCookie.set('token_jwt', data.token)

        navigate('/')
    }

    return (
      <Layout className='login-bg'>
        <div className='login-wrap'>
            <div className='login-title'>线索管理平台</div>
            <div className='login-container'>
                <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                >
                <Form.Item
                    name="username"
                    rules={[{ required: true, message: 'Please input your Username!' }]}
                >
                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[{ required: true, message: 'Please input your Password!' }]}
                >
                    <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Password"
                    />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" style={{width: '100%'}} className="login-form-button">
                        Log in
                    </Button>
                </Form.Item>
                </Form>
            </div>
        </div>
        <Link className={'beian'} to='https://beian.miit.gov.cn/'>京ICP备20011911号-1</Link>
        
      </Layout>
    )
};

export default Login;