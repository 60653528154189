import React from "react";
import { Table, Space, Select, DatePicker, Button, Upload,  message, Input, Badge } from 'antd'
import { UploadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs'
import { useRequest, dispatchAsync } from 'friday-async'
import { useApiSelector } from "src/hooks";
import { useLeadColumns } from './useLeadColumns'
import jsCookie from 'js-cookie'
import config from 'src/config'
import { defaultDate } from 'src/utils'

interface ProjectLeadProps {
    projectInfo: Record<any, any>
    id: string
}

const ProjectLead: React.FC<ProjectLeadProps> = (props) => {
    const { id, projectInfo = {} } = props
    const { startTime, endTime } = projectInfo

    const initialDate = defaultDate(startTime, endTime)
    
    const [value, setValue] = React.useState({
        date: initialDate,
        dataString: initialDate.format('YYYY-MM-DD')
    })

    const [fitlerVals ,setFilterVals] = React.useState({
        status: undefined,
        tel: '',
    })

    const apis = useApiSelector()

    const { tableProps, revalidate, dataArray } = useRequest(apis.project.leadList({
        projectId: id, 
        createTime: value.dataString,
        ...fitlerVals
    }), {
        paginated: true,
    })

    const { columns } = useLeadColumns(revalidate)

    const onChange = (val, dateString) => {

        setValue({
            date: val,
            dataString: dateString
        })
    }   

    const uploadProps = {
        name: 'file',
        accept: '.xlsx',
        showUploadList: false,
        headers: {
            Authorization: `Bearer ${jsCookie.get('token_jwt')|| ''}`
        },
        action: `${config.host}/api/platform/lead/uploadLeads?projectId=${id}&createTime=${value.dataString}`,
        onChange(info) {
            if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
            revalidate()
            message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    const download = async () => {
        const {responseBlob, error} = await dispatchAsync(apis.project.downloadTemplate())
        if (error) return message.error('下载失败')
        const downloadUrl = window.URL.createObjectURL(new Blob([responseBlob]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', '线索上传模版.xlsx'); 
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    const downloadLeads = async () => {
        const {responseBlob, error} = await dispatchAsync(apis.project.downloadLeads({
            projectId: id,
            createTime: value.dataString,
            ...fitlerVals
        }))
        if (error) return message.error('下载失败')
        const downloadUrl = window.URL.createObjectURL(new Blob([responseBlob]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', `线索明细_${dayjs().format('YYYY-MM-DD')}.xlsx`); 
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    return (
        <Space direction='vertical' style={{width: '100%'}}>
            <div style={{display: 'flex'}}>
                <Space style={{flex: 1}}>
                    <DatePicker value={value.date} onChange={onChange} format='YYYY-MM-DD' />
                    <Select 
                        allowClear
                        style={{width: 140}}
                        value={fitlerVals.status}
                        onChange={(value) => setFilterVals({...fitlerVals, status: value})}
                        placeholder='请选择线索状态'
                        options={[
                            { label: '已推送', value: 'send'},
                            { label: '未推送', value: 'unsend'},
                        ]}
                    />
                    <Input 
                        value={fitlerVals.tel} 
                        placeholder='请输入手机号' 
                        onChange={(e) => setFilterVals({...fitlerVals, tel: e.target.value})}
                    />
                    <Badge overflowCount={100000} count={dataArray.length}><Button onClick={downloadLeads}>导出线索数据</Button></Badge>
                </Space>
                <Space>
                    <Button disabled={!dataArray.length} icon={<UploadOutlined  />} onClick={download}>下载线索模版</Button>
                    <Upload {...uploadProps}>
                        <Button icon={<UploadOutlined />}>上传{value.dataString}线索</Button>
                    </Upload>
                </Space>
            </div>
            <Table 
                columns={columns}
                {...tableProps as any}
            />
        </Space>
    )
}

export default ProjectLead;