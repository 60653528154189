const clueStatusMap = {
    send: {
        label: '已发送'
    },
    unsend: {
        label: '未发送'
    }
}

export const useLeadReturnColumns = () => {
    const columns = [{
        title: '易车线索ID',
        dataIndex: 'demandId',
        key: 'demandId',
    },{
        title: '手机号',
        dataIndex: 'tel',
        key: 'tel',
    },{
        title: '姓名',
        dataIndex: 'name',
        key: 'name',
    },{
        title: '回传时间',
        dataIndex: 'createTime',
        key: 'createTime',
    },{
        title: '易车线索状态',
        dataIndex: 'demandStatus',
        key: 'demandStatus',
    },]
    return {
        columns
    }
}