import { createGetApi, createPostApi } from 'friday-async';
import config from 'src/config'

class Apis {
  public login = createPostApi<{username: string, password: string}>({
    url: '/user/login',
    baseURL: config.host + '/api',
  });
}

export default Apis;