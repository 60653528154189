import React from "react";
import { Table, Space, DatePicker, Select, Badge, Button, message } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { useRequest, dispatchAsync } from 'friday-async'
import { useApiSelector } from "src/hooks";
import { useLeadReturnColumns } from './useLeadReturnColumns'
import { defaultDate } from 'src/utils'

const { RangePicker } = DatePicker;

type RangeValue = [Dayjs | null, Dayjs | null] | null;

interface ProjectLeadReturnProps {
    projectInfo: Record<any, any>
    id: string
}

const ProjectLeadReturn: React.FC<ProjectLeadReturnProps> = (props) => {
    const { id, projectInfo = {} } = props
    const { startTime, endTime } = projectInfo

    const endlDate = defaultDate(startTime, endTime)
    
    const [dates, setDates] = React.useState<RangeValue>(null);

    const [value, setValue] = React.useState({
        startTime: endlDate.add(-6, 'day'), 
        endTime: endlDate,
        startTimeString: endlDate.add(-7, 'day').format('YYYY-MM-DD'),
        endTimeString: endlDate.format('YYYY-MM-DD'),
        status: undefined
    })
    const apis = useApiSelector()

    const { tableProps, dataArray } = useRequest(apis.project.leadReturnList({
        projectId: id, 
        startTime: value.startTimeString,
        endTime: value.endTimeString,
        demandStatus: value.status,
    }), {
        paginated: true,
    })

    const { columns } = useLeadReturnColumns()

    const onChange = (val, dateString) => {
        setValue({
            ...value,
            startTime: val[0], 
            endTime: val[1],
            startTimeString: dateString[0],
            endTimeString: dateString[1],
        })
    }  

    const disabledDate = (current: Dayjs) => {
        if (!dates) {
            return false;
        }
        const tooLate = dates[0] && current.diff(dates[0], 'days') >= 7;
        const tooEarly = dates[1] && dates[1].diff(current, 'days') >= 7;
        return !!tooEarly || !!tooLate;
    };

    const onOpenChange = (open: boolean) => {
        if (open) {
          setDates([null, null]);
        } else {
          setDates(null);
        }
    };

    const downloadReturnLeads = async () => {
        const {responseBlob, error} = await dispatchAsync(apis.project.downloadReturnLeads({
            projectId: id, 
            startTime: value.startTimeString,
            endTime: value.endTimeString,
            demandStatus: value.status,
        }))
        if (error) return message.error('下载失败')
        const downloadUrl = window.URL.createObjectURL(new Blob([responseBlob]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', `易车线索回传_${value.startTimeString}至${value.endTimeString}.xlsx`); 
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    return (
        <Space direction='vertical' style={{width: '100%'}}>
            <Space>
                <RangePicker 
                    allowClear={false}
                    disabledDate={disabledDate} 
                    value={dates || [value.startTime, value.endTime]}
                    onChange={onChange} 
                    format='YYYY-MM-DD' 
                    onOpenChange={onOpenChange}
                    onCalendarChange={(val) => {
                        setDates(val);
                    }}
                    changeOnBlur
                />
                <Select
                    allowClear
                    style={{width: 140}}
                    value={value.status}
                    onChange={(v) => setValue({...value, status: v})}
                    placeholder='请选择线索状态'
                    options={[
                        { label: '有效线索', value: 'valid'},
                        { label: '重复线索', value: 'repeat'},
                        { label: '无效线索', value: 'invalid'},
                        { label: '溢出线索', value: 'overflow'},
                    ]}
                />
                <Badge overflowCount={100000}  count={dataArray.length} offset={[10, 10]}>
                    <Button disabled={!dataArray.length} onClick={downloadReturnLeads}>导出线索回传数据</Button>
                </Badge>
            </Space>
            <Table 
                columns={columns}
                {...tableProps as any}
            />
        </Space>
    )
}

export default ProjectLeadReturn;