import React from "react";
import { Table, Button, Space } from 'antd'
import { PlusOutlined } from '@ant-design/icons';
import { useColumnsService } from './useColumnsService'
import { useApiSelector } from 'src/hooks'
import { useRequest } from 'friday-async'
import CreateDrawer from './CreateDrawer'

const List = () => {

  const [drawerInfo, setDrawerInfo] = React.useState({
    visible: false,
    info: null
  })
  
  const apis = useApiSelector();

  const { tableProps, revalidate  } = useRequest(apis.project.list(), {
    paginated: true,
  })

  const { columns } = useColumnsService({ revalidate, setDrawerInfo })

  return (
    <Space direction='vertical' style={{width: '100%'}}>
        <Button 
            type="primary" 
            ghost 
            icon={<PlusOutlined />} 
            onClick={() => {
                setDrawerInfo({...drawerInfo, visible: true})
            }}
        >
            新建项目
        </Button>
        <Table rowKey={'name'} columns={columns} {...tableProps as any} />;
        <CreateDrawer
            info={drawerInfo.info}
            visible={drawerInfo.visible}
            onClose={() => {
                setDrawerInfo({ info: null, visible: false})
                revalidate()
            }}
        />
    </Space>
  )
}

export default List;