// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
#root {
  height: 100%;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.ant-layout {
  height: 100%;
}
.ant-layout-heade {
  background: #000 !important;
}
.demo-logo-vertical {
  width: 64px;
  height: 64px;
  border-radius: 6px;
  line-height: 32px;
  color: #fff;
}
.demo-logo-vertical img {
  width: 100%;
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/styles/layout.less"],"names":[],"mappings":"AAAA;;EACI,YAAA;AAEJ;AAAA;EACI,SAAA;EACA,8JAAA;EAGA,mCAAA;EACA,kCAAA;EACA,YAAA;AAAJ;AAGA;EACI,+EAAA;AADJ;AAKA;EACI,YAAA;AAHJ;AAMA;EACI,2BAAA;AAJJ;AAOA;EACI,WAAA;EACA,YAAA;EACA,kBAAA;EACA,iBAAA;EACA,WAAA;AALJ;AAAA;EAOQ,WAAA;EACA,YAAA;AAJR","sourcesContent":["html, #root {\n    height: 100%;\n}\nbody {\n    margin: 0;\n    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n        sans-serif;\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n    height: 100%;\n}\n\ncode {\n    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n.ant-layout {\n    height: 100%;\n}\n\n.ant-layout-heade {\n    background: #000!important;\n}\n\n.demo-logo-vertical {\n    width: 64px;\n    height: 64px;\n    border-radius: 6px;\n    line-height: 32px;\n    color: #fff;\n    img {\n        width: 100%;\n        height: 100%;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
