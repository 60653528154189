import devConfig from './config.dev'
import proConfig from './config.prd'


const env = (process.env as any).NODE_ENV


const getCofig = () => {
   if (env ===  'production') {
        return proConfig;
   }
   return devConfig;
}


const config = getCofig();

export default config;