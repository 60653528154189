import { get, debounce } from 'lodash';
import { httpAxios } from 'friday-async';
import config from 'src/config'
import jsCookie from 'js-cookie'
import { message } from 'antd';
import { router } from 'src/navigation'


export const axiosConifg = {
  baseURL: config.host + '/api/platform',
  headers: {
    Accept: 'application/json;charset=utf-8',
    'Content-Type': 'application/json;charset=utf-8',
  },
  timeout: 20000,
};

const errorNotice = debounce((text) => {
  // MessagePlugin.error(text);
}, 500);


export const isApiTimeout = (error: any): boolean => error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1;

export const isNetworkError = (error: any): boolean => error.message === 'Network Error';

export const redirectLogin = debounce(() => {
  message.error('登陆超时')
  router.navigate('/login')
}, 500);

export const axiosInstance = httpAxios(axiosConifg, {
  
  requestSuccessHook(config: any) {
    
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${jsCookie.get('token_jwt')|| ''}`
    }
    return config;
  },
  responseErrorHooks(error: any) {
    const { status } = error.response;
    // 超时、网络异常
    if (isApiTimeout(error)) {
      // eslint-disable-next-line
   // errorNotice('接口请求超时');
    }

    if (isNetworkError(error)) {
      // errorNotice('网络异常');
    }

    if (error.response && status === 401) {
      redirectLogin()

      return Promise.reject(error);
    }

    // 系统错误
    if (error.response && status >= 400) {
      const notice = get(error, 'response.data.message') || '系统异常';
      errorNotice(notice);
    };
    return Promise.reject(error);
  },
  responseSuccessHooks: (response: any) => {
    const { data } = response;

    return data;
  },

});