import React from "react";
import { PageContainer } from '@ant-design/pro-components';
import {  useParams, Link } from 'react-router-dom'
import { Descriptions, Badge } from 'antd'
import { useRequest } from 'friday-async'
import { useApiSelector } from 'src/hooks'
import dayjs from 'dayjs'
import { statusMap, typeName } from '../constants'
import ProjectCalendar from './ProjectCalendar'
import ProjectLead from './ProjectLead'
import ProjectLeadReturn from './ProjectLeadReturn'
import { isEmpty } from 'lodash'
import ProjectSendInfo from './ProjectSendInfo'
import './index.less'


const tabMap = {
    calendar: ProjectCalendar,
    lead: ProjectLead,
    leadreturn: ProjectLeadReturn,
    sendInfo: ProjectSendInfo
}

const ProjectDetail = () => {
    const apis = useApiSelector()
    
    const { id, type = 'calendar' } = useParams()


    const [tabIndex, setTabIndex] = React.useState(type)

    const { dataJson, isValidating } = useRequest(apis.project.detail({_id: id!}))

    const CurrentComponent = tabMap[tabIndex] || tabMap.calendar;

    const { project: d = {}, yiche = {} } =dataJson

    const { startTime, endTime } = yiche

    const isTimeEffect = dayjs().isBetween(startTime, endTime, 'day', '[]')
    
    const status = isTimeEffect && d.status === 'effective' ?  'effective' : 'unEffective'

    return (
        <PageContainer
            title={d.name }
            content={
                <>
                    <Descriptions column={3} style={{ marginBlockEnd: -16 }}>
                        <Descriptions.Item label="项目类型">{typeName[d.type]}</Descriptions.Item>
                        <Descriptions.Item label="项目周期">
                        {`${d.startTime} ~ ${d.endTime}`}
                        </Descriptions.Item>
                        <Descriptions.Item label="项目状态">
                        <Badge color={statusMap[status]?.color} text={statusMap[status]?.label} />
                        </Descriptions.Item>
                        <Descriptions.Item label="易车项目日限额">
                        { `${yiche?.limitOfDay}条`}
                        </Descriptions.Item>
                        <Descriptions.Item label="项目推送时间">
                        { `每天${d.pushTime}开始推送`}
                        </Descriptions.Item>
                    </Descriptions>
                </>
            }
            tabList={[
                {
                    tab: <Link className="link" to={`/project/${id}/calendar`}>项目推送日历</Link>,
                    key: 'calendar',
                },
                {
                    tab: <Link className="link" to={`/project/${id}/lead`}>线索明细</Link>,
                    key: 'lead',
                },
                {
                    tab: <Link className="link" to={`/project/${id}/leadreturn`}>易车回传线索</Link>,
                    key: 'leadreturn',
                },
                {
                    tab: <Link className="link" to={`/project/${id}/sendInfo`}>易车项目信息明细</Link>,
                    key: 'sendInfo',
                },
            ]}
            tabActiveKey={tabIndex}
            onTabChange={(val) => setTabIndex(val)}
        >   
        {
            !isEmpty(d) && !isValidating ? <CurrentComponent id={id} projectInfo={d} yicheInfo={yiche} /> : 'loading'
        }

        </PageContainer>
    )
}

export default ProjectDetail