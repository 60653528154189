import React from "react";
import { Calendar, Badge, Progress, Button } from 'antd'
import type { BadgeProps, CalendarProps,  } from 'antd';
import dayjs from 'dayjs'
import type { Dayjs } from 'dayjs';
import { useRequest } from 'friday-async'
import { useApiSelector } from 'src/hooks'
import { find } from 'lodash'
interface ProjectCalendarProps {
    projectInfo: Record<any, any>
    id: string
}


const ProjectCalendar: React.FC<ProjectCalendarProps> = (props) => {

    const { projectInfo: p = {}, id } = props
    const [value, setValue] = React.useState({
      date: dayjs(),
      mode: 'month',
    })


    const apis = useApiSelector()

    const { dataArray, isValidating } = useRequest(apis.project.getCalendar({
      projectId: id,
      startTime: p .startTime, 
      endTime: p .endTime, 
      mode: value.mode,
    }))

    const dateCellRender = (value: Dayjs) => {

      const limitInfo =  find(dataArray, (o) => dayjs(o.createTime).format('YYYY-MM-DD') === value.format('YYYY-MM-DD'))
        
      if (!limitInfo && value.isBetween(p.startTime, p.endTime, 'day', '[]')) {
        return <Badge status='warning' text='无推送记录' />
      }

      if (!limitInfo) return null
      
      return (
          <ul className="events">
              <Badge status={'success'} text={`日限额: ${limitInfo.yicheProjectLimit}条`} />
              <Badge status={'success'} text={`已推送: ${limitInfo.sendLeadTotal}条`} />
              <Progress percent={(limitInfo.sendLeadTotal/limitInfo.yicheProjectLimit)*100} size="small"/>
          </ul>
      );
    };

    const monthCellRender = (value) => {
      const limitInfo =  find(dataArray, (o) => {
        return dayjs(o.createTime).format('YYYY-MM') === value.format('YYYY-MM')
      })

      if (!limitInfo && value.isBetween(p.startTime, p.endTime, 'month', '[]')) {
        return <Badge status='warning' text='本月无推送记录' />
      }

      if (!limitInfo) return null
      
      return (
        <ul className="events">
          <li>
            <Badge status={'success'} text={`当月推送线索量: ${limitInfo.count}条`} />
          </li>
        </ul>
      );
        
    }

    const cellRender: CalendarProps<Dayjs>['cellRender'] = (current, info) => {
        if (info.type === 'date') return dateCellRender(current);
        if (info.type === 'month') return monthCellRender(current);
        return info.originNode;
    }

    const onPanelChange =  (value, mode) => {
      setValue({
        date: value,
        mode
      })
    }

    return (
        <div>
            <Calendar 
                value={value.date} 
                mode={value.mode as any}
                cellRender ={cellRender}
                validRange={[dayjs(p .startTime), dayjs(p.endTime).add(1, 'day')]} 
                onPanelChange={onPanelChange}
            />
        </div>
    )
}

export default ProjectCalendar;