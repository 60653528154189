import { Typography, Space, message, Tag, Badge } from 'antd'
import { Link } from 'react-router-dom'
import { dispatchAsync } from 'friday-async'
import { useApiSelector } from 'src/hooks'
import { typeName, statusMap, projectStatusMap } from '../constants'
import dayjs from 'dayjs'

export const useColumnsService = ({ revalidate, setDrawerInfo }) => {
  const apis = useApiSelector()


  const deleteProject = async (id) => {
    const {error} = await dispatchAsync(apis.project.batchDelete({ids: [id]}))
    if (error) return 
    message.success('删除成功')
    revalidate()
  } 

  const editProject = (record) => {
    setDrawerInfo({
      visible: true,
      info: record
    })
  }

  const columns = [
    {
      title: '项目名称',
      dataIndex: 'name',
      key: 'name',
      render(name, record) {
        return <Link key={name} to={`/project/${record._id}/calendar`}>{name}</Link>
      }
    },
    {
      title: '项目类型',
      dataIndex: 'type',
      key: 'type',
      render(type) {
        return typeName[type]
      }
    },
   
    {
      title: '项目周期',
      dataIndex: 'startTime',
      key: 'startTime',
      render(_, record) {
        return `${record.startTime} ~ ${record.endTime}`
      }
    },
    {
      title: '对应易车项目',
      dataIndex: 'yicheProjectId',
      key: 'yicheProjectId',
    },
    {
      title: '当日推送时间',
      dataIndex: 'pushTime',
      key: 'pushTime',
      render(pushTime) {
        return `每天${pushTime}开始推送`
      }
    },
    {
      title: '项目状态',
      dataIndex: 'startTime',
      key: 'startTime',
      render(_, record) {
        const { startTime, endTime } = record
        const isEffect = dayjs().isBetween(startTime, endTime, 'day', '[]')
        console.log(isEffect, 'isEffect')
        const type = isEffect ? 'effective' : 'unEffective'

        return <Tag color={projectStatusMap[type]?.color}>{projectStatusMap[type]?.label}</Tag>
      }
    },
    {
      title: '推送状态',
      dataIndex: 'status',
      key: 'status',
      render(type, record) {
        const { startTime, endTime } = record
        const isTimeEffect = dayjs().isBetween(startTime, endTime, 'day', '[]')
        
        const status = isTimeEffect && type === 'effective' ?  'effective' : 'unEffective'

        return <Badge color={statusMap[status]?.color} text={statusMap[status]?.label} />
      }
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      render(_, record) {
        return (
          <Space>
            <Typography.Text onClick={() => editProject(record)}>编辑</Typography.Text>
          </Space>
        )
      }
    }
  ];

  return {
    columns
  }
}