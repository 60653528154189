import React from 'react';
import { AsyncRequestProvider } from 'friday-async';
import { axiosInstance } from './axiosInstance';
// import setDisasterRecovery from './setDisasterRecovery';

const NextAsyncRequestProvider = AsyncRequestProvider as any;
const ApiProvider = ({ children }: any) => (

  <NextAsyncRequestProvider value={{
    axiosInstance,
    // axiosExpands: {
    // //   setDisasterRecovery,
    // },
  }}>
    {children}
  </NextAsyncRequestProvider>
);

export default ApiProvider;