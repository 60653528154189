import { message, Space, Typography, Tag } from 'antd'
import { dispatchAsync } from 'friday-async'

import { useApiSelector } from 'src/hooks'

const clueStatusMap = {
    send: {
        label: '已推送',
        color: '#87d068'
    },
    unsend: {
        label: '未推送',
        color: '#f50'
    }
}

export const useLeadColumns = (revalidate) => {
    const apis = useApiSelector()
    const deleteLead = async (id) => {
        const { error } = await dispatchAsync(apis.project.batchDeleteLead({ids: [id]}))
        console.log(error, 'err')
        if (error) return message.error('删除失败')

        message.success('删除成功')
        revalidate()
    }
    const columns = [{
        title: '手机号',
        dataIndex: 'tel',
        key: 'tel',
    },{
        title: '姓名',
        dataIndex: 'name',
        key: 'name',
    },{
        title: '性别',
        dataIndex: 'sex',
        key: 'sex',
    },{
        title: '车系ID',
        dataIndex: 'cmdId',
        key: 'cmdId',
    },{
        title: '车系名称',
        dataIndex: 'cmdName',
        key: 'cmdName',
    },{
        title: '车型ID',
        dataIndex: 'carId',
        key: 'carId',
    }, {
        title: '车型名称',
        dataIndex: 'carName',
        key: 'carName',
    },{
        title: '城市ID',
        dataIndex: 'cityId',
        key: 'cityId',
    },{
        title: '城市名称',
        dataIndex: 'cityName',
        key: 'cityName',
    },{
        title: '经销商ID',
        dataIndex: 'dealerId',
        key: 'dealerId',
    },{
        title: '经销商名称',
        dataIndex: 'dealerName',
        key: 'dealerName',
    },{
        title: '线索状态',
        dataIndex: 'status',
        key: 'status',
        render(status) {
            return <Tag color={clueStatusMap[status].color}>{clueStatusMap[status].label}</Tag>
        }
    },{
        title: '上传时间',
        dataIndex: 'createTime',
        key: 'createTime',
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      render(_, record) {
        return (
          <Space>
            <Typography.Text onClick={() => deleteLead(record._id)}>删除</Typography.Text>
          </Space>
        )
      }
    }]
    return {
        columns
    }
}