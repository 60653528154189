import React, { useState } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Outlet, Link } from 'react-router-dom'
import { Layout, Menu, Button, theme } from 'antd';
import logo from '../../images/logo.jpeg'

const { Header, Sider, Content } = Layout;

const Index = () => {
    const [collapsed, setCollapsed] = useState(false);
    const { token: { colorBgContainer }, } = theme.useToken();

    return (
        <Layout>
        <Header style={{ display: 'flex', alignItems: 'center' }}>
          <div className="demo-logo-vertical"><img src={logo}/> </div>
          <Menu style={{marginLeft: 30}} theme="dark" mode="horizontal" defaultSelectedKeys={['1']} items={[
              {
                key: '1',
                label: <Link to='/project'>项目管理</Link>,
              },
              {
                key: '2',
                label: <Link to='/media/report'>线索报表</Link>,
              },
            ]} />
        </Header>
        <Layout style={{ padding: '24px' }}>
          <Content
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
              background: colorBgContainer,
            }}
          >
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    )
};

export default Index;