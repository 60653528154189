import React from "react";
import { dispatchAsync, useRequest } from 'friday-async'
import { Drawer, Space , Button, Form, Alert, Row, Col, Input, Select, DatePicker, Radio, TimePicker, message } from 'antd'
import dayjs from 'dayjs'
import { useApiSelector } from 'src/hooks'


const format = 'HH:mm';

const { Option } = Select;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

interface CreateDrawerProps {
  onClose: () => void
  visible: boolean
  info: null | Record<any, any>
}

const CreateDrawer: React.FC<CreateDrawerProps> = (props) => {
  const { onClose, visible, info } = props;
  const [form] = Form.useForm();
  const apis = useApiSelector()
  const [yicheInfo, setYicheInfo ] = React.useState<any>(null)


  const { dataArray } = useRequest(apis.project.demandlist())


  const isEdit = !!info

  React.useEffect(() => {
    if (isEdit) {
      form.setFieldsValue({
        ...info,
        pushTime: dayjs(info!.pushTime, 'HH:mm'),
        startTime: [dayjs(info!.startTime), dayjs(info!.endTime)],
      })
    }
    return () => {
      form.resetFields()
    }
  }, [info])

  const onChangeYIChe = async (crid) => {
    const {error , dataJson } = await dispatchAsync(apis.project.demandDetail({crid}))
    if (error) return 
    const {BeginTime,EndTime } = dataJson?.Requirement
    setYicheInfo(dataJson?.Requirement)
    form.setFieldValue(
      'startTime', [dayjs(BeginTime), dayjs(EndTime)]
    )
  }

  const onFinish = async () => {
    try {
      const result = await form.validateFields()
      const payload = {
        ...result,
        pushTime: result.pushTime.format('HH:mm'),
        startTime: result.startTime[0].format('YYYY-MM-DD'),
        endTime: result.startTime[1].format('YYYY-MM-DD'),
      }

      if (isEdit) {
        payload['_id'] = info._id
      }
      const { error } = await dispatchAsync(apis.project.craete(payload))
      if (error) return message.error('新建失败')
      message.success(isEdit ? '编辑成功' : '新建成功')
      onClose()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Drawer
      title={`${isEdit ? info.name : '新建项目'}`}
      width={620}
      onClose={onClose}
      open={visible}
      destroyOnClose
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      footer={
        <Space>
          <Button key={1} style={{width: 120}} onClick={onClose}>取消</Button>
          <Button key={2} style={{width: 120}} type="primary" onClick={onFinish}>确认</Button>
        </Space>
      }
    >
      <Form {...layout} form={form}>
        <Row gutter={12}>
          <Col span={18}>
            <Form.Item
              name="name"
              label="项目名称"
              rules={[{ required: true, message: '请输入项目名称'}]}
            >
              <Input placeholder="请输入项目名称 " />
            </Form.Item>
            <Form.Item
              name="type"
              label="项目类型"
              initialValue={'fixed'}
              rules={[{ required: true, message: '请输入项目类型' }]}
            >
              <Radio.Group>
                <Radio value={'flex'}>灵活周期</Radio>
                <Radio value={'fixed'}>固定周期</Radio>
              </Radio.Group>
            </Form.Item>
            
        
            <Form.Item
              name="yicheProjectId"
              label="易车项目"
              rules={[{ required: true, message: '请选择易车项目' }]}
            >
              <Select placeholder="请选择易车项目" onChange={onChangeYIChe}>
                {dataArray.map(i => {
                  return <Option key={i} value={i}>{i}</Option>
                })}
              </Select>
            </Form.Item>
            {yicheInfo && 
              <Form.Item
                label='易车项目数据参考：'
              >
                <Alert type='warning' message={`易车项目周期：${yicheInfo?.BeginTime} 到${yicheInfo?.EndTime}, 下方项目周期可参考` } />
              </Form.Item>
            }
            

            <Form.Item
              name="startTime"
              label="项目周期"
              rules={[{ required: true, message: '请选择项目周期' }]}
            >
              <DatePicker.RangePicker format='YYYY-MM-DD' />
            </Form.Item>

            <Form.Item
              name="pushTime"
              label="当日推送时间"
              initialValue={dayjs('00:00', 'HH:mm')}
              rules={[{ required: true, message: '请选择当日推送时间' }]}
            >
              <TimePicker format={format} />
            </Form.Item>
            {/* <Form.Item
              name="pushFrequency"
              label="推送频率"
              initialValue={'2'}
              rules={[{ required: true, message: '请选择推送频率' }]}
            >
              <Select placeholder="请选择推送频率">
                <Option value="2">2分钟一次</Option>
                <Option value="4">4分钟一次</Option>
                <Option value="6">6分钟一次</Option>
                <Option value="8">8分钟一次</Option>
                <Option value="10">10分钟一次</Option>
              </Select>
            </Form.Item> */}
            <Form.Item
              name="status"
              label="项目状态"
              initialValue={'effective'}
              rules={[{ required: true, message: '请选择项目状态' }]}
            >
              <Radio.Group>
                <Radio value={'effective'}>开启推送</Radio>
                <Radio value={'unEffective'}>暂不开启</Radio>
              </Radio.Group>
            </Form.Item>
            </Col>
        </Row>
      </Form>
    </Drawer>
  )
}

export default CreateDrawer;