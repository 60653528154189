import React from 'react';
import ProjectList from 'src/pages/Project/List';
import ProjectDetail from 'src/pages/Project/Detail';
// import Monitor from 'src/pages/Monitor/List';
import Analysis from 'src/pages/Analysis'
import Layout from 'src/pages/Layout';
import Login from 'src/pages/Login';


export const childrenRouter = [
  
  {
    index: true,
    name: '项目',
    path: '/project',
    exact: true,
    element: <ProjectList />,
  },
  {
    index: true,
    name: '项目详情',
    path: '/project/:id',
    element: <ProjectDetail />,
  },
  {
    index: true,
    name: '日历',
    path: '/project/:id/:type',
    element: <ProjectDetail />,
  },
  {
    index: true,
    name: '线索推送报表',
    path: '/media/report',
    element: <Analysis />,
  },
  
  // {
  //   index: true,
  //   name: '监测告警',
  //   path: '/monitor',
  //   exact: true,
  //   element: <Monitor />
  // },

];

export default [
  {
    path: '/',
    element: <Layout />,
    children: [
      { index: true, exact: true, element: <ProjectList /> },
      ...childrenRouter,
    ],
  },
  {
    index: true,
    name: '项目',
    path: '/login',
    exact: true,
    element: <Login />,
  },
];