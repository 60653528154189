import React from "react";
import { Typography, Collapse, Divider, Descriptions} from 'antd';


const formatPro = (ListPro: any[] = [] ) => {
    return ListPro.map(i => {
        return {
            key: i.ProId, 
            label: `${i.ProName} id: ${i.ProId}`, 
            children: i.ListCity.map(e=> (<div>{`${e.CityName}  id: ${e.CityId}`}</div>))
        }
    })
}

const formatOther = (keyValArray: any[] = [] ) => {
    return keyValArray.map(i => {
        return (<Typography.Text>名称:{i.name} id: {i.id}</Typography.Text>)
    })
}

const ProjectSendInfo = ({ yicheInfo }) => {

    console.log(yicheInfo, 'yicheInfo')
    const { yicheCrdLimit }  = yicheInfo || {}
    return (
        <div>
            {yicheCrdLimit.map(crd => {
                return (
                    <div key={crd.crdId}>
                        <Typography.Title level={4}>需求ID: {crd.crdId}</Typography.Title>
                        <Descriptions column={1} style={{ marginBlockEnd: -16 }}>
                            <Descriptions.Item label="需求总额度">{crd.limit}</Descriptions.Item>
                        </Descriptions>
                        
                        <Divider orientation="left">省市范围</Divider>
                        <Collapse items={formatPro(crd.ListPro)} />
                        <Divider orientation="left">品牌</Divider>
                        {formatOther(crd.ListCmb)}
                        <Divider orientation="left">子品牌</Divider>
                        {formatOther(crd.ListCmk)}
                        <Divider orientation="left">车系</Divider>
                        {formatOther(crd.ListCmd)}
                    </div>
                )
            })}

        </div>
    )
}

export default ProjectSendInfo